<template>
    <div class="card-body p-4">
        <h5 class="mb-1 text-center">{{$t('generale.registrati')}}</h5>
        <p>{{$t('components.registrazione.descrizione')}}</p>
        <form class="form-horizontal" @submit.prevent="signupForm()">

            <div class="row">
                <div class="col-md-12">
                    <div class="form-group form-group-custom mb-2">
                        <input type="text" class="form-control" id="username" v-model.trim="$v.email.$model" required>
                        <label for="username">{{$t('components.registrazione.form.email.label')}}</label>
                        <div class="error pl-vertical-align-input custom-font" v-if="!$v.email.email">{{$t('components.registrazione.form.email.erroreFormato')}}</div>
                    </div>

                    <div class="form-group form-group-custom my-3">
                        <input type="text" class="form-control" id="verifyUsername" v-model.trim="$v.emailConfirm.$model" required>
                        <label for="verifyUsername">{{$t('components.registrazione.form.confermaEmail.label')}}</label>
                        <div class="error pl-vertical-align-input custom-font" v-if="!$v.emailConfirm.sameAsEmail">{{$t('components.registrazione.form.confermaEmail.erroreCorrispondenza')}}</div>
                    </div>

                    <div class="form-group form-group-custom my-3">
                        <input type="password" class="form-control" v-model.trim="$v.password.$model" id="userpassword" required>
                        <label for="userpassword">{{$t('components.registrazione.form.password.label')}}</label>
                        <div class="error pl-vertical-align-input custom-font" v-if="!$v.password.validatePassword">{{$t('components.registrazione.form.password.erroreFormato')}}</div>
                    </div>

                    <div class="form-group form-group-custom mb-3">
                        <input type="password" class="form-control" v-model.trim="$v.passwordConfirm.$model" id="userpasswordconfirm" required>
                        <label for="userpasswordconfirm" class="custom-animation-transform">{{$t('components.registrazione.form.confermaPassword.label')}}</label>
                        <div class="error pl-vertical-align-input custom-font" v-if="!$v.passwordConfirm.sameAsPassword">{{$t('components.registrazione.form.confermaPassword.erroreCorrispondenza')}}</div>
                    </div>

                    <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input" id="gdpr-checkbox" required>
                        <label style="font-size:11px" class="custom-control-label pt-1" for="gdpr-checkbox">{{$t('components.registrazione.hoLettoECompreso')}} <a href="#" data-toggle="modal" data-target="#myModal" data-backdrop="static"> {{$t('components.registrazione.informativaPrivacy')}}</a></label>
                    </div>

                    <div class="custom-control custom-checkbox pt-2">
                        <input type="checkbox" class="custom-control-input" id="term-conditionCheck" required>
                        <label style="font-size:11px" class="custom-control-label pt-1" for="term-conditionCheck">
                            {{$t('components.registrazione.form.autorizzoIlTrattamentoEmail.label')}} <a type="button" data-toggle="modal" data-target="#termsAndConditionsModal" class="text-primary">{{$t('components.registrazione.form.autorizzoIlTrattamentoEmail.link')}}</a>
                        </label>
                    </div>

                    <div class="custom-control custom-checkbox pt-2">
                        <input type="checkbox" class="custom-control-input" id="user-data-check"  v-model="flagCommercial">
                        <label style="font-size:11px" class="custom-control-label pt-1" for="user-data-check">{{$t('components.registrazione.autorizzoIlTrattamentoEmail.label')}}</label>
                    </div>

                    <div class="mt-4">
                        <button class="btn btn-primary btn-block waves-effect waves-light" type="submit">{{$t('generale.registrati')}}</button>
                    </div>
                    <div class="mt-4">
                        <h5 class="mb-0 text-center">{{$t('generale.or_sign')}}</h5>
                    </div>
                    <SocialLogin></SocialLogin>

                    <div class="mt-4 text-center">
                        <i class="mdi mdi-account-circle mr-1"></i> {{$t('components.registrazione.seiGiaRegistrato')}} <router-link to="/auth?type=login" class="custom-link"> ({{$t('generale.accedi')}})</router-link>
                    </div>
                    <div class="text-center">
                        <a target="_blank" href="https://www.concorsando.it/blog/contattaci" rel="noopener">{{$t('components.login.haiBisognoDiAiuto')}}</a>
                    </div>
                </div>
            </div>
        </form>

        <!-- Modal GDPR -->
        <div id="myModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" style="display: none;">
            <div class="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title mt-0" id="myModalLabel">{{$t('components.registrazione.modale.gdpr.titolo')}}</h5>
                    </div>
                    <div id="modal-body" class="modal-body">
                        <p v-html="$t('components.registrazione.modale.gdpr.testo')"></p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary waves-effect waves-light" data-dismiss="modal">{{$t('generale.ok')}}</button>
                    </div>
                </div><!-- /.modal-content -->
            </div><!-- /.modal-dialog -->
        </div>
        <!-- /Modal GDPR -->

        <!-- Modal error -->
        <div id="modalError" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modalErrorLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title mt-0" id="modalErrorLabel">{{$t('components.registrazione.modale.errore.titolo')}}</h5>
                    </div>
                    <div id="modal-error-body" class="modal-body">
                        <h5 class="font-size-16">{{$t('components.registrazione.modale.errore.sottotitolo')}}</h5>
                        <p id="message-body"></p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary waves-effect waves-light" data-dismiss="modal">{{$t('generale.ok')}}</button>
                    </div>
                </div><!-- /.modal-content -->
            </div><!-- /.modal-dialog -->
        </div>
        <!-- /Modal error -->
    </div>
</template>

<script>
    import SocialLogin from "./SocialLogin"
    import { email, sameAs } from 'vuelidate/lib/validators'
    import { validatePassword } from '@/helpers/utilities'
    import {MODULES} from "@/store/modules-enum";
    import {AUTH_ACTIONS} from "@/store/auth/actions-enum";
    import {mapActions} from "vuex";
    import $ from "jquery";
    import _ from 'lodash';
    import AffiliateManager from "../lib/AffiliateManager";
    import {loginTransition} from "../flows/login/loginFlow";
    import {LoginStates} from "../flows/login/loginStates";

    export default {
        name: "Login",
        components: {
            SocialLogin
        },
        data () {
            return {
                email: '',
                emailConfirm: '',
                password: '',
                passwordConfirm: '',
                flagCommercial: false
            }
        },
        beforeMount() {
            this.updateAffiliateCookie();
        },
        validations: {
            email: {
                email
            },
            emailConfirm: {
                sameAsEmail: sameAs('email')
            },
            password: {
                validatePassword
            },
            passwordConfirm: {
                sameAsPassword: sameAs('password')
            }
        },
        methods: {
            ...mapActions(MODULES.AUTH, [AUTH_ACTIONS.SIGNUP]),
            signupForm () {
                const context = this
                this.$v.$touch()
                if (!this.$v.$invalid) {
                    $('.loading').show()
                    this.signup({
                        isSignup: true,
                        emailComunicazioni: this.email,
                        username: this.email,
                        flag_privacy: (!this.flagCommercial) ? 1 : 2,
                        social: "concorsando",
                        password: this.password
                    })
                        .then(() => {
                            loginTransition(LoginStates.REGISTER);
                        })
                        .catch((error) => {
                            $('.loading').hide()
                            if (error.response !== undefined) {
                                if (error.response.status === 409) {
                                    $('#modalError').modal('show')
                                    $('#message-body').html(context.$t('components.registrazione.modale.errore.utenteGiaEsistente'))
                                    return
                                }
                            }
                            $('#modalError').modal('show')
                            $('#message-body').html(context.$t('components.registrazione.modale.errore.generale'))
                        })
                }
            },
            queryIncludesAffiliate () {
                return Object.keys(this.$route.query)
                    .filter(x => x !== "type")
                    .length > 0
            },
            updateAffiliateCookie () {
                if (this.queryIncludesAffiliate()) {
                    const uri = window.location.href.split("?");
                    const q = uri[1] || "";
                    AffiliateManager.instance.saveAffiliateString(q);
                }
            },
        }
    }
</script>

<style scoped lang="scss">
    html.theme-light, html.theme-dark {
        .pl-vertical-align-input {
            padding-left: 14px;
        }

        .form-group-custom .form-control:focus + .custom-animation-transform, .form-group-custom .form-control:valid + .custom-animation-transform {
            transform: translate(-1.2em, -96%) scale(.8);
            color: #3051d3;
        }

        .custom-font {
            font-size: 0.85em;
            line-height: 1.1;
        }
         .custom-link {
            color: blue; /* Cambia il colore del testo */
            text-decoration: none; /* Rimuovi la sottolineatura se desiderato */
            }
        .custom-link:hover {
                color: green; /* Cambia il colore al passaggio del mouse */
                }
    }
</style>
