<template>
    <div class="card-body p-4">
        <h5 class="mb-1 text-center">{{$t('generale.accedi')}}</h5>
        <p>{{$t('components.login.descrizione')}}</p>
        <form class="form-horizontal" @submit.prevent="loginUser()">

            <div class="row">
                <div class="col-md-12">
                    <div class="form-group form-group-custom mb-2">
                        <input type="text" class="form-control" v-model.trim="$v.email.$model" id="username" required v-bind:readonly="!showButtonAvanti">
                        <label v-bind:class="{forced: !showButtonAvanti}" for="username">{{$t('components.login.form.email.label')}}</label>
                        <div class="error pl-vertical-align-input custom-font" v-if="!$v.email.email">{{$t('components.login.form.email.erroreFormato')}}</div>
                        <div class="error pl-vertical-align-input custom-font" v-if="$v.email.$dirty && !$v.email.required">{{$t('components.login.form.email.erroreRequired')}}</div>
                    </div>

                    <div class="form-group form-group-custom my-3" v-show="showButtonAvanti">
                        <button class="btn btn-primary btn-block waves-effect waves-light" type="button" @click="checkUserEmail()">
                            {{$t('components.login.form.avanti.label')}}
                        </button>
                    </div>
                    <div class="form-group form-group-custom my-3" v-show="!showButtonAvanti">
                        <input :type="isPasswordVisible ? 'text' : 'password'" class="form-control" v-model="password" id="userpassword" required>
                         <label for="userpassword">{{$t('components.login.form.password.label')}}</label>
                         <i @click="togglePasswordVisibility" :class="isPasswordVisible ? 'mdi mdi-eye-off-outline mr-1':'mdi  mdi-eye-outline mr-1'"></i>

                    </div>
                    <div class="row" v-show="!showButtonAvanti">
                        <div class="col-md-5">
                            <div class="custom-control custom-checkbox">
                                <input type="checkbox" class="custom-control-input" v-model="rememberMe" id="customControlInline">
                                <label class="custom-control-label" for="customControlInline">{{$t('components.login.form.ricordami')}}</label>
                            </div>
                        </div>
                        <div class="col-md-7">
                            <div class="text-md-right mt-3 mt-md-0">
                                <a type="button" data-toggle="modal" data-target="#forgotPasswordModal" class="text-muted"><i class="mdi mdi-lock"></i> {{$t('components.login.passwordDimenticata')}}</a>
                            </div>
                        </div>
                    </div>
                    <div class="mt-4" v-show="!showButtonAvanti">
                        <button class="btn btn-primary btn-block waves-effect waves-light" type="submit">{{$t('generale.entra')}}</button>
                    </div>
                    <div class="mt-4">
                        <h5 class="mb-0 text-center">{{$t('generale.or')}}</h5>
                    </div>
                    <SocialLogin></SocialLogin>

                    <div class="mt-4 text-center">
                        <i class="mdi mdi-account-circle mr-1"></i> {{$t('components.login.nonHaiAncoraUnAccount')}} <router-link :to="{ name: 'Auth', query: { type: 'signup' }}" class="custom-link"> ({{$t('generale.registrati')}})</router-link>
                    </div>
                    <div class="text-center">
                        <a target="_blank" href="https://www.concorsando.it/blog/contattaci" rel="noopener">{{$t('components.login.haiBisognoDiAiuto')}}</a>
                    </div>
                </div>
            </div>
        </form>

        <!-- Modal login error -->
        <div id="modalMessage" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modalMessageLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title mt-0" id="modalMessageLabel">{{$t('components.login.modal.errore.titolo')}}</h5>
                    </div>
                    <div id="modal-body-message" class="modal-body">
                        <p id="message-body-message"></p>

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary waves-effect waves-light" data-dismiss="modal">{{$t('generale.ok')}}</button>
                    </div>
                </div><!-- /.modal-content -->
            </div><!-- /.modal-dialog -->
        </div>
        <!-- /Modal login error -->

        <!-- Modal login error -->
        <div id="modalLoginError" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modalLoginErrorLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title mt-0" id="modalLoginErrorLabel">{{$t('components.login.modal.errore.titolo')}}</h5>
                    </div>
                    <div id="modal-body-login-error" class="modal-body">
                        <h5 class="font-size-16">{{$t('components.login.modal.errore.sottotitolo')}}</h5>
                        <p id="message-body-login-error"></p>

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary waves-effect waves-light" data-dismiss="modal">{{$t('generale.ok')}}</button>
                    </div>
                </div><!-- /.modal-content -->
            </div><!-- /.modal-dialog -->
        </div>
        <!-- /Modal login error -->

        <!-- Modal restore password error -->
        <div id="modalRestorePasswordError" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modalRestorePasswordErrorLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title mt-0" id="modalRestorePasswordErrorLabel">{{$t('components.login.modal.resettaPassword.errore.titolo')}}</h5>
                    </div>
                    <div id="modal-body-rp-error" class="modal-body">
                        <p id="message-body-rp-error"></p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary waves-effect waves-light" data-dismiss="modal">{{$t('generale.ok')}}</button>
                    </div>
                </div><!-- /.modal-content -->
            </div><!-- /.modal-dialog -->
        </div>
        <!-- /Modal restore password error -->

        <!-- Forgot password modal -->
        <div class="modal fade" id="forgotPasswordModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLongTitle">{{$t('components.login.recuperaPassword')}}</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="form-group form-group-custom mb-2 w-65 mx-auto">
                            <input type="text" class="form-control" v-model.trim="$v.forgotPasswordEmail.$model" id="forgotPasswordEmail" required>
                            <label for="forgotPasswordEmail">{{$t('components.login.form.email.label')}}</label>
                            <div class="error pl-vertical-align-input custom-font" v-if="!$v.forgotPasswordEmail.email">{{$t('components.login.form.email.erroreFormato')}}</div>
                        </div>
                        <vue-recaptcha class="w-65 mx-auto" ref="recaptcha" :sitekey="reCaptchaSiteKey" @verify="onVerify" @expired="captchaNotAccepted = true"></vue-recaptcha>
                    </div>
                    <div class="modal-footer">
                        <button type="button" id="sendButton" @click="restorePasswordRequest()" data-dismiss="modal" :class="[((captchaNotAccepted === true || $v.forgotPasswordEmail.email === false || forgotPasswordEmail === '') ? 'cursorNotAllowed' : ''), 'btn btn-primary']" :disabled="(captchaNotAccepted === true || $v.forgotPasswordEmail.email === false || forgotPasswordEmail === '')">{{$t('generale.invia')}}</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- /Forgot password modal -->
    </div>
</template>

<script>
    import $ from 'jquery'
    import SocialLogin from "./SocialLogin"
    import { email, required } from 'vuelidate/lib/validators'
    import VueRecaptcha from 'vue-recaptcha';
    import {MODULES} from "@/store/modules-enum";
    import {AUTH_ACTIONS} from "@/store/auth/actions-enum";
    import {mapActions} from "vuex";
    import {AUTH_MUTATIONS} from "@/store/auth/mutations-enum";
    import Swal from "sweetalert2";
    import {promisify} from "@/lib/utils";
    import UtenteApi from "@/lib/ConcorsandoApi/api/UtenteApi";
    import {storageKeysEnum} from "@/helpers/enums";
    import * as _ from 'lodash';
    import RuntimeConfig from "@/helpers/RuntimeConfig";
    import {loginTransition} from "../flows/login/loginFlow";
    import {LoginStates} from "../flows/login/loginStates";

    const utenteApi = new UtenteApi();

    export default {
        name: "Login",
        components: {
            SocialLogin,
            VueRecaptcha
        },
        data () {
            return {
                email: '',
                password: '',
                isPasswordVisible: false ,
                rememberMe: false,
                forgotPasswordEmail: '',
                captchaNotAccepted: true,
                reCaptchaSiteKey: RuntimeConfig.instance.reCaptchaSiteKey,
                showButtonAvanti: true,
            }
        },
        validations: {
            email: {
                email,
                required,
            },
            forgotPasswordEmail: {
                email
            }
        },
        beforeDestroy() {
            this.$refs.recaptcha.reset()
        },
        mounted() {
            const context = this
            try {
                this.setupRememberEmail();
            } catch (e) {}
            $('#forgotPasswordModal').on('hidden.bs.modal', function () {
                context.forgotPasswordEmail = ''
                context.$v.forgotPasswordEmail.$model = ''
            })
        },
        methods: {
            ...mapActions(MODULES.AUTH, [AUTH_ACTIONS.LOGIN, AUTH_ACTIONS.GET_RESTORE_PASSWORD]),
            loginUser () {
                const context = this
                this.$v.$touch()
                if (!this.$v.$invalid) {
                    $('.loading').show()
                    this.login({
                        isLogin: true,
                        social: "concorsando",
                        username: this.email,
                        password: this.password
                    })
                        .then((response) => {
                            if (response === null) {
                                $('.loading').hide()
                                $('#modalLoginError').modal('show')
                                $('#message-body-login-error').html(context.$t('components.login.modal.errore.credenziali'))
                            } else {
                                try {
                                    this.updateRememberEmail();
                                } catch (e) {}
                                loginTransition(LoginStates.LOGIN)
                            }
                        })
                        .catch((error) => {
                            $('.loading').hide()
                            $('#modalLoginError').modal('show')
                            if (error !== undefined) {
                                if (error.status === 429) {
                                    $('#message-body-login-error').html(context.$t('components.login.modal.errore.troppeRichieste') + ' <a href=\'http://m.me/AssistenzaTecnicaConcorsando\'>Assistenza</a>')
                                    return
                                }
                            }
                            $('#message-body-login-error').html(context.$t('components.login.modal.errore.generale'))
                        })
                }
            },
            updateRememberEmail () {
                if (this.rememberMe && !this.$v.email.$invalid && this.email !== "") {
                    localStorage.setItem(storageKeysEnum.EMAIL, this.email);
                }
                else if (!this.rememberMe) {
                    localStorage.removeItem(storageKeysEnum.EMAIL);
                }
                else {
                    console.error("Richiesto salvataggio di una email non valida")
                }
            },
            setupRememberEmail () {
                const email = localStorage.getItem(storageKeysEnum.EMAIL);
                if (_.isString(email) && !_.isEmpty(email)) {
                    this.rememberMe = true;
                    this.email = email;
                    this.$v.email.$touch();
                }
            },
            onVerify (response) {
                if (response !== null && response !== '') {
                    this.captchaNotAccepted = false
                }
            },
            restorePasswordRequest () {
                const context = this
                $('.loading').show()
                this.getRestorePassword(this.forgotPasswordEmail)
                    .then(() => {
                        $('.loading').hide()
                        Swal.fire({
                            title: this.$t('components.login.modal.resettaPassword.sweetAlert.title'),
                            text: this.$t('components.login.modal.resettaPassword.sweetAlert.text'),
                            icon: 'success',
                            confirmButtonText: this.$t('generale.ok')
                        })
                    })
                    .catch(() => {
                        $('.loading').hide()
                        $('#modalRestorePasswordError').modal('show')
                        $('#message-body-rp-error').html(context.$t('components.login.modal.resettaPassword.errore.generale'))
                    })
            },
            checkUserEmail() {
                this.$v.email.$touch();
                if (!this.$v.email.$invalid) {
                    $('.loading').show()
                    promisify((...args) => utenteApi.getSocialByEmail(...args), this.email)
                        .then(res => {
                            if (Array.isArray(res) && res.length > 0) {
                                if (res.includes("concorsando")) {
                                    this.showButtonAvanti = false;
                                }
                                else {
                                    let socialsLis = res.map(x => {
                                        switch (x) {
                                            case "facebook":
                                                return "Facebook"
                                            case "google":
                                                return "Google"
                                            case "apple":
                                                return "Apple"
                                            default:
                                                return x
                                        }
                                    })
                                        .map(social => `<li>${social}</li>`)
                                    $('#modalMessage').modal('show')
                                    const messageHtml = this.$t('components.login.modal.errore.messaggioSocialAssociati_1') +
                                        socialsLis.join("") +
                                        this.$t('components.login.modal.errore.messaggioSocialAssociati_2')
                                    $('#message-body-message').html(messageHtml)
                                }
                            }
                            else {
                                throw {type: "CUSTOM_ERROR", reason: "NOT_FOUND"};
                            }
                        })
                        .catch(error => {
                            if (error !== undefined && (
                                (error.type === "CUSTOM_ERROR" && error.reason === "NOT_FOUND")
                                || (error.status === 404)
                            )) {
                                $('#modalMessage').modal('show')
                                $('#message-body-message').html(this.$t('components.login.modal.errore.utenteNonTrovato'))
                            }
                            else {
                                console.error(error);
                                $('#modalLoginError').modal('show')
                                $('#message-body-login-error').html(this.$t('components.login.modal.errore.generale'))
                            }
                        })
                        .finally(() => {
                            $('.loading').hide()
                        })
                }
            },
        togglePasswordVisibility() {
            this.isPasswordVisible = !this.isPasswordVisible;
           }
        }
    }
</script>

<style scoped lang="scss">
    html.theme-light, html.theme-dark {
        .pl-vertical-align-input {
            padding-left: 12px;
        }

        .w-65 {
            width: 65%;
        }

        .custom-font {
            font-size: 0.85em;
            line-height: 1.1;
        }

        .form-group-custom .form-control + label.forced {
            transform: translate(-0.75em, -96%) scale(0.8);
            color: #3051D3;
        }
        .form-group-custom i {
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
            cursor: pointer;
            font-size: 20px;
            }
        .custom-link {
            color: blue; /* Cambia il colore del testo */
            text-decoration: none; /* Rimuovi la sottolineatura se desiderato */
            }
        .custom-link:hover {
                color: green; /* Cambia il colore al passaggio del mouse */
                }
 
    }
</style>
